<template>
  <div>

    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">

      <div class="card-header cursor-pointer">

        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">List of Consumers</h3>
        </div>
        <!-- <router-link
          to="/consumers/add"
          class="btn btn-primary align-self-center"
          >Add Consumer</router-link
        > -->
      </div>
      <div class="card-body p-7">

        <Table :columns="columns" ref="table" :action="actions.SEARCH_CONSUMERS">

          <template v-slot:birthday="slotProps">

            <span class="text-nowrap">{{slotProps.row.birthday}}</span>

          </template>

          <template v-slot:actions="slotProps">

            <div>

              <div class="btn-group" role="group" aria-label="Basic example">

                <router-link :to="'/consumers/edit/'+slotProps.row.id" class="btn btn-sm btn-light-warning font-weight-bold"><i class="fs-1-3 la la-edit"></i></router-link>

                <!-- <DeleteRecord :id="slotProps.row.id" :action="actions.DELETE_CONSUMER" :callback="slotProps.search"/> -->

              </div>

            </div>

          </template>

        </Table>

      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { reactive, onMounted, computed, ref } from 'vue';
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { Actions } from "@/store/enums/ConsumerEnums.ts";
  import Table from '@/components/custom/Table.vue';
  // import DeleteRecord from '@/components/custom/DeleteRecord.vue';

  export default {
    name : 'ConsumerList',
    components : {
      Table,
      // DeleteRecord
    },
    setup(){
      onMounted(() => {
        setCurrentPageBreadcrumbs("Consumers",[
          {title : "Consumers List",to : "/consumers/list"}
        ]);
      });

      const actions = computed(() => {
        return Actions;
      })



      const columns = [
        {name : 'name', title : 'Name', sortable : true, sort : 'asc', searchable : true},
        {name : 'address', title : 'Address', sortable : false, sort : null, searchable : true},
        {name : 'birthday', title : 'Birthday',sortable : true, sort : null, searchable : true},
        {name : 'email', title : 'Email',sortable : true, sort : null, searchable : true},
        {name : 'mobile', title : 'Mobile',sortable : false, sort : null, searchable : true},
        {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
      ]

      return {
        columns,
        actions
      }

    }
  }
</script>
