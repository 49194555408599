
  import { reactive, onMounted, computed, ref } from 'vue';
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { Actions } from "@/store/enums/ConsumerEnums.ts";
  import Table from '@/components/custom/Table.vue';
  // import DeleteRecord from '@/components/custom/DeleteRecord.vue';

  export default {
    name : 'ConsumerList',
    components : {
      Table,
      // DeleteRecord
    },
    setup(){
      onMounted(() => {
        setCurrentPageBreadcrumbs("Consumers",[
          {title : "Consumers List",to : "/consumers/list"}
        ]);
      });

      const actions = computed(() => {
        return Actions;
      })



      const columns = [
        {name : 'name', title : 'Name', sortable : true, sort : 'asc', searchable : true},
        {name : 'address', title : 'Address', sortable : false, sort : null, searchable : true},
        {name : 'birthday', title : 'Birthday',sortable : true, sort : null, searchable : true},
        {name : 'email', title : 'Email',sortable : true, sort : null, searchable : true},
        {name : 'mobile', title : 'Mobile',sortable : false, sort : null, searchable : true},
        {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
      ]

      return {
        columns,
        actions
      }

    }
  }
